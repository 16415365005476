import Modal from "@material-ui/core/Modal";
import { useUpdateProfileMuatation } from "new-dw-ui/src/lib/api/mutationhooks";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export const SwitchedToNewUImodal = (props: any) => {
  const modalProps = { ...props };
  const dispatch = useDispatch();
  const updateProfile = useSelector((state: any) => {
    return state.isProfileUpdated;
  });

  const updateProfileMutation: any = useUpdateProfileMuatation({
    onSuccess: (data: any, vars: any) => {
      dispatch({
        type: "updateProfile",
        isProfileUpdated: !updateProfile,
      });
      toast.success("Updated Successfully");
      if (vars.preferences) {
        localStorage.setItem("user", JSON.stringify(data));
      }
    },
    onError: () => {},
  });

  const handleUiChange = async () => {
    let userVals: any = localStorage.getItem("user");
    userVals = JSON.parse(userVals);
    if (!userVals.preferences) {
      userVals.preferences = {};
    }
    userVals.preferences.usingNewUi = true;

    updateProfileMutation.mutate({
      preferences: {
        ...userVals.preferences,
      },
    });

    localStorage.setItem("user", JSON.stringify(userVals));

    if (updateProfileMutation.isLoading) return <>'updating....'</>;
    if (updateProfileMutation.isError)
      return <>`Submission error! ${updateProfileMutation.error}`</>;
  };

  const onCloseModal = async() => {
    await handleUiChange()
    modalProps.setOpen(false)
  };

  return (
    <div>
      <Modal open={modalProps.open} onClose={() => onCloseModal()}>
        <div
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            width: "400px",
            height: "210px",
            border: "none",
            backgroundColor: "#fff",
            boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px",
            borderRadius: "10px",
            outline: "none",
          }}
        >
          <div style={{ margin: "25px" }}>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "5px",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              You are switched to the New DesignWizard UI
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "5px",
                fontSize: "16px",
              }}
            >
              We've phased out the old DesignWizard UI. Enjoy the new and
              improved DesignWizard UI.
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  padding: "5px 10px",
                  backgroundColor: "rgb(216 216 216)",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => onCloseModal()}
              >
                Close
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
